import * as React from 'react'
import PropTypes from "prop-types"
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import slugify from "@sindresorhus/slugify"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import BackgroundImage from 'gatsby-background-image'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination} from 'swiper';

// Components
import Layout from '../../components/layout/Layout'
import ProduitCard from '../../components/card/Produit'

SwiperCore.use([Pagination]);

const energy_classes = [
  {
    "value": 'A++',
    "class": 'AAA',
  },
  {
    "value": 'A+',
    "class": 'AA',
  },
  {
    "value": 'A',
    "class": 'A',
  },
  {
    "value": 'B',
    "class": 'B',
  },
  {
    "value": 'C',
    "class": 'C',
  },
  {
    "value": 'D',
    "class": 'D',
  },
  {
    "value": 'E',
    "class": 'E',
  },
  {
    "value": 'F',
    "class": 'F',
  },
  {
    "value": 'G',
    "class": 'G',
  },
];

// Produit
const Produit = ({ pageContext, data }) => {

  const pageImage = { 
    url: data.produit.frontmatter.hero_image.publicURL,
    secureUrl: data.produit.frontmatter.hero_image.publicURL,
    type: data.produit.frontmatter.hero_image.type,
    width: data.produit.frontmatter.hero_image.childImageSharp.gatsbyImageData.width,
    height: data.produit.frontmatter.hero_image.childImageSharp.gatsbyImageData.height,
    alt: data.produit.frontmatter.titre
  };

  const pageSchema = { 
    type: "product",
    name: data.produit.frontmatter.titre,
    description: data.produit.frontmatter.description,
    sku: data.produit.frontmatter.sku,
    images: data.produit.frontmatter.produit_images,
    brand: data.produit.frontmatter.marque,
  };

  return (
    <Layout 
      pageTitle={data.produit.frontmatter.titre} 
      pageDescription={data.produit.frontmatter.description} 
      pageKeywords={data.produit.frontmatter.marque} 
      pageImage={pageImage}
      pageSchema={pageSchema}
      headerClassPosition="sticky-top"
    >
      {
        data.produit.frontmatter.hero_image.childImageSharp.fluid
        ?
        <BackgroundImage
          Tag="section"
          className="product-hero d-flex align-items-center justify-content-center"
          fluid={data.produit.frontmatter.hero_image.childImageSharp.fluid}
        >
          <div key="breadcrumbs-section" className="breadcrumbs">
            <div className="container">
              <div className="float-end">
                <ol>
                  <li>
                    <Link to={`/`} className="text-reset">Accueil</Link>
                  </li>
                  <li>
                    <Link to={`/produits`} className="text-reset">Produits</Link>
                  </li>
                  {
                    data.produit.frontmatter.categorie
                    ? 
                    <li>
                      <Link to={`/produits/categories/${slugify(data.produit.frontmatter.categorie)}`} className="text-reset">{data.produit.frontmatter.categorie}</Link>
                    </li>
                    : null
                  } 
                  {
                    data.produit.frontmatter.sous_categorie
                    ? 
                    <li>
                      <Link to={`/produits/categories/${slugify(data.produit.frontmatter.categorie)}/sous-categories/${slugify(data.produit.frontmatter.sous_categorie)}`} className="text-reset">{data.produit.frontmatter.sous_categorie}</Link>
                    </li>
                    : null
                  } 
                  {
                    data.produit.frontmatter.marque
                    ? 
                    <li>
                      <Link to={`/produits/marques/${slugify(data.produit.frontmatter.marque)}`} className="text-reset">{data.produit.frontmatter.marque}</Link>
                    </li>
                    : null
                  }
                </ol>
              </div>
            </div>
          </div>
          <div className="container position-relative">
            {
              data.produit.frontmatter.titre
              ? <h1>{data.produit.frontmatter.titre}</h1>
              : null
            }
            {
              data.produit.frontmatter.marque
              ? <Link to={`/produits/marques/${slugify(data.produit.frontmatter.marque)}`} className="h2">{data.produit.frontmatter.marque}</Link>
              : null
            }
          </div>
        </BackgroundImage>
        : null
      }
      {
        data.produit.frontmatter.hero_image.childImageSharp.fluid
        ? null
        :
        <section key="breadcrumbs-section" className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              {
                data.produit.frontmatter.titre
                ? <h2>{data.produit.frontmatter.titre}</h2>
                : null
              }
              <ol>
                <li>
                  <Link to={`/`} className="text-reset">Accueil</Link>
                </li>
                <li>
                  <Link to={`/produits`} className="text-reset">Produits</Link>
                </li>
                {
                  data.produit.frontmatter.categorie
                  ? 
                  <li>
                    <Link to={`/produits/categories/${slugify(data.produit.frontmatter.categorie)}`} className="text-reset">{data.produit.frontmatter.categorie}</Link>
                  </li>
                  : null
                } 
                {
                  data.produit.frontmatter.sous_categorie
                  ? 
                  <li>
                    <Link to={`/produits/categories/${slugify(data.produit.frontmatter.categorie)}/sous-categories/${slugify(data.produit.frontmatter.sous_categorie)}`} className="text-reset">{data.produit.frontmatter.sous_categorie}</Link>
                  </li>
                  : null
                } 
                {
                  data.produit.frontmatter.marque
                  ? 
                  <li>
                    <Link to={`/produits/marques/${slugify(data.produit.frontmatter.marque)}`} className="text-reset">{data.produit.frontmatter.marque}</Link>
                  </li>
                  : null
                }
              </ol>
            </div>
          </div>
        </section>
      }
      <section key="details-section" className="product-details">
        <div className="container">
          <div className="row gy-4">
            <div key="images" className="col-lg-4  offset-lg-1">
              {data.produit.frontmatter.produit_images ?
                <Swiper
                  modules={[Navigation, Pagination]}
                  navigation
                  slidesPerView={'1'}
                  centeredSlides={true} 
                  autoHeight={true}
                  spaceBetween={30}
                  pagination={{"clickable": true}}
                  className="product-description-produit-slider swiper"
                >
                  {
                    data.produit.frontmatter.produit_images.map((image,index) => (
                      <SwiperSlide key={index} className="swiper-slide">
                        <GatsbyImage
                          image={getImage(image)}
                          className="swiper-slide"
                          alt={data.produit.frontmatter.titre}
                        />
                      </SwiperSlide>                  
                    ))
                  }
                </Swiper>
              : null}
            </div>
            <div key="text" className="col-lg-6  offset-lg-1">
              <div key="info" className="product-info">
                {
                  data.produit.frontmatter.marque
                  ? 
                  <div className="mb-4 border-bottom">
                    <Link to={`/marques/${slugify(data.produit.frontmatter.marque)}`}>
                      <GatsbyImage
                        image={getImage(data.marque.frontmatter.hero_image_16x9)}
                        className="card-img-top"
                        alt={data.marque.frontmatter.hero_image_alt}
                      />
                    </Link>
                  </div>
                  : null
                }
                {
                  data.produit.frontmatter.titre
                  ? <h3>{data.produit.frontmatter.titre}</h3>
                  : null
                }
                <ul>
                  {
                    data.produit.frontmatter.marque
                    ? 
                    <li key="marque"><strong className="me-1">Marque:</strong>
                      <Link to={`/marques/${slugify(data.produit.frontmatter.marque)}`} className="text-reset">{data.produit.frontmatter.marque}</Link>
                    </li>
                    : null
                  }
                  {
                    data.produit.frontmatter.categorie
                    ? 
                    <li key="categorie"><strong className="me-1">Catégorie:</strong>
                      <Link to={`/produits/categories/${slugify(data.produit.frontmatter.categorie)}`} className="text-reset">{data.produit.frontmatter.categorie}</Link>
                    </li>
                    : null
                  }
                  {
                    data.produit.frontmatter.sous_categorie
                    ? 
                    <li key="sous-categorie"><strong className="me-1">Sous catégorie:</strong>
                      <Link to={`/produits/categories/${slugify(data.produit.frontmatter.categorie)}/sous-categories/${slugify(data.produit.frontmatter.sous_categorie)}`} className="text-reset">{data.produit.frontmatter.sous_categorie}</Link>
                    </li>
                    : null
                  }
                  {
                    data.produit.frontmatter.couleurs
                    ? <li key="couleurs"><strong className="me-1">Couleurs:</strong>
                          {
                            data.produit.frontmatter.couleurs.map((couleur, index) => [
                              index > 0 && ", ",
                              <Link key={index} to={`/produits/couleurs/${slugify(couleur)}`} className="text-reset">{couleur}</Link>
                            ])
                          }
                      </li>
                    : null
                  }
                </ul>
                {data.produit.frontmatter.classe ?
                    <div className="diagnostics energy minimal">
                      {
                        energy_classes.map((energy_class, index) => (
                          <div key={index} className="wrapper">
                            <div className={`segment level-${energy_class.class} ${data.produit.frontmatter.classe === energy_class.value ? 'focus' : ''}`} data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-original-title={energy_class.value}>
                              <div className="letter">{energy_class.value}</div>
                              <div className="detail">
                                <div className="arrow detail-arrow"></div>
                              </div>												
                            </div><div className={`arrow line-arrow level-${energy_class.class}`}></div>
                          </div>
                        ))
                      }
                    </div>
                  : null
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      <section key="description-technical-section" className="product-details">
          <div className="container">
            <div key="description-technical-tecnique" className="product-description-technical">
                <h2>Informations techniques</h2>
                <div className="product-info">
                  <ul>
                    {
                      data.produit.frontmatter.hauteur
                      ? <li key="hauteur"><strong className="me-1">Hauteur:</strong>{data.produit.frontmatter.hauteur}</li>
                      : null
                    }
                    {
                      data.produit.frontmatter.largeur
                      ? <li key="largeur"><strong className="me-1">Largeur:</strong>{data.produit.frontmatter.largeur}</li>
                      : null
                    }
                    {
                      data.produit.frontmatter.profondeur
                      ? <li key="profondeur"><strong className="me-1">Profondeur:</strong>{data.produit.frontmatter.profondeur}</li>
                      : null
                    }
                    {
                      data.produit.frontmatter.poids
                      ? <li key="poids"><strong className="me-1">Poids:</strong>{data.produit.frontmatter.poids}</li>
                      : null
                    }
                    {
                      data.produit.frontmatter.rendement
                      ? <li key="rendement"><strong className="me-1">Rendement:</strong>{data.produit.frontmatter.rendement}</li>
                      : null
                    }
                    {
                      data.produit.frontmatter.puissance_calorifique
                      ? <li key="puissance-calorifique"><strong className="me-1">Puissance calorifique:</strong>{data.produit.frontmatter.puissance_calorifique}</li>
                      : null
                    }
                    {
                      data.produit.frontmatter.capacite_de_chauffe
                      ? <li key="capacite-de-chauffe"><strong className="me-1">Capacité de chauffe:</strong>{data.produit.frontmatter.capacite_de_chauffe}</li>
                      : null
                    }
                    {
                      data.produit.frontmatter.autonomie_horaire_max
                      ? <li key="autonomie-horaire-max"><strong className="me-1">Autonomie:</strong>{data.produit.frontmatter.autonomie_horaire_max}</li>
                      : null
                    }
                    {
                      data.produit.frontmatter.dimension_buches
                      ? <li key="dimension-buches"><strong className="me-1">Dimension des bûches:</strong>{data.produit.frontmatter.dimension_buches}</li>
                      : null
                    }
                    {
                      data.produit.frontmatter.capacite_reservoir
                      ? <li key="capacite-reservoir"><strong className="me-1">Capacité du réservoir:</strong>{data.produit.frontmatter.capacite_reservoir}</li>
                      : null
                    }
                    {
                      data.produit.frontmatter.classe
                      ? <li key="class"><strong className="me-1">Classe:</strong>{data.produit.frontmatter.classe}</li>
                      : null
                    }
                    {
                      data.produit.frontmatter.flamme_verte
                      ? <li key="flamme-verte"><strong className="me-1">Flamme Verte:</strong>{data.produit.frontmatter.flamme_verte}</li>
                      : null
                    }
                    {
                      data.produit.frontmatter.indice_performance_environnementale
                      ? <li key="indice-performance-environnementale"><strong className="me-1">Indice Performance Environnementale:</strong>{data.produit.frontmatter.indice_performance_environnementale}</li>
                      : null
                    }
                    {
                      data.produit.frontmatter.puissance
                      ? <li key="puissance"><strong className="me-1">Puissance:</strong>
                          <ul className="list-unstyled">
                            {
                              data.produit.frontmatter.puissance.map((puissance, index) => (
                                <li key={index}>{puissance}</li>             
                              ))
                            }
                          </ul>
                        </li>
                      : null
                    }
                  </ul>
                </div>
              </div>
          </div>
      </section>
      {data.produit.frontmatter.presentation_images ?
        <section key="description-presentation-section" className="product-details">
          <div className="container">
            <div className="gy-4">
              {data.produit.frontmatter.presentation_images ?
                <Swiper
                  modules={[Navigation, Pagination]}
                  navigation
                  slidesPerView={'1'}
                  centeredSlides={true} 
                  autoHeight={true}
                  spaceBetween={30}
                  pagination={{"clickable": true}}
                  className="product-description-presentation-slider swiper"
                >
                  {
                    data.produit.frontmatter.presentation_images.map((image,index) => (
                      <SwiperSlide key={index} className="swiper-slide">
                        <GatsbyImage
                          image={getImage(image)}
                          className="swiper-slide"
                          alt={data.produit.frontmatter.titre}
                        />
                      </SwiperSlide>                  
                    ))
                  }
                </Swiper>
              : null}
            </div>
          </div>
        </section>
      : null}
      <section key="description-general-section" className="product-details">
          <div className="container">
            <div key="description-general" className="product-description-general">
                <h2>Informations générales</h2>
                <div className="text-justify">
                  <MDXRenderer>
                    {data.produit.body}
                  </MDXRenderer>
                </div>
              </div>
          </div>
      </section>
      {data.produit.frontmatter.videos ? 
        <section key="videos-section" className="videos">
          <div className="container">
            <div key="videos-title" className="section-title">
              <h2>Vidéos</h2>
            </div>
            <div key="videos" className="row">
                {
                  data.produit.frontmatter.videos.map((video, index) => (
                    <div key={index} className="col-lg-4">
                      <div className="ratio ratio-16x9 mb-3">
                        <iframe src={video} title={data.produit.frontmatter.titre} allowFullScreen></iframe>
                      </div>
                    </div>
                  ))
                }
            </div>
          </div>
        </section>
      : null }
      {data.autresMarqueCategorieProduits.nodes.length ? 
        <section key="autres-marque-categorie-produits-section" className="products">
          <div className="container">
            <div key="autres-marque-categorie-produits-title" className="section-title">
              <h2>Autres {data.produit.frontmatter.categorie} de {data.produit.frontmatter.marque}</h2>
            </div>
            <div key="autres-marque-categorie-produits" className="row">
                {
                  data.autresMarqueCategorieProduits.nodes.map((produit, index) => (
                    <ProduitCard key={index} data={produit}/>
                  ))
                }
            </div>
          </div>
        </section>
      : null }
      {data.autresMarqueProduits.nodes.length ? 
        <section key="autres-marque-categorie-produits-section" className="products">
          <div className="container">
            <div key="autres-marque-produits-title" className="section-title">
              <h2>Autres produits de {data.produit.frontmatter.marque}</h2>
            </div>
            <div key="autres-marque--produits" className="row">
                {
                  data.autresMarqueProduits.nodes.map((produit,index) => (
                    <ProduitCard key={index} data={produit}/>
                  ))
                }
            </div>
          </div>
        </section>
        : null }
    </Layout>
  )
}

Produit.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    produit: PropTypes.shape({
      node: PropTypes.shape({
        frontmatter: PropTypes.shape({
          titre: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          keywords: PropTypes.string.isRequired,
        }),
        fields: PropTypes.shape({
          slug: PropTypes.string.isRequired,
        }),
      }),
    }).isRequired
  }),
}

export default Produit

export const query = graphql`
  query ($id: String, $marque: String, $categorie: String) {
    produit: mdx(id: {eq: $id}, fileAbsolutePath: {regex: "/produits/"}) {
      frontmatter {
        titre
        description
        keywords
        marque
        categorie
        sous_categorie
        couleurs
        sku
        hauteur
        largeur
        profondeur
        poids
        rendement
        puissance_calorifique
        capacite_de_chauffe 
        autonomie_horaire_max
        dimension_buches
        capacite_reservoir
        classe
        flamme_verte
        indice_performance_environnementale
        card_image {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        card_image_alt
        hero_image {
          publicURL
          childImageSharp {
            gatsbyImageData
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        presentation_images {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        produit_images {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        videos
        actif
        date(formatString: "MMMM D, YYYY")
      }
      body
    }
    marque: mdx(
      fileAbsolutePath: {regex: "/marques/"}
      frontmatter: {nom: {eq: $marque}}
    ) {
      frontmatter {
        nom
        marque
        pays
        site_internet
        hero_image {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        hero_image_16x9 {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        videos
        actif
        date(formatString: "MMMM D, YYYY")
      }
    }
    autresMarqueCategorieProduits: allMdx(
      filter: {fileAbsolutePath: {regex: "/produits/"}, id: {ne: $id}, frontmatter: {marque: {eq: $marque}, categorie: {eq: $categorie}}}
      sort: {fields: frontmatter___date, order: ASC}
      limit: 6
    ) {
      nodes {
        frontmatter {
          titre
          marque
          categorie
          card_image {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
          card_image_alt
          hero_image {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
          hero_image_alt
          hero_image_credit_link
          hero_image_credit_text
          accueil
          actif
          date(formatString: "MMMM D, YYYY")
        }
        id
        slug
      }
      totalCount
    }
    autresMarqueProduits: allMdx(
      filter: {fileAbsolutePath: {regex: "/produits/"}, id: {ne: $id}, frontmatter: {marque: {eq: $marque}, categorie: {ne: $categorie}}}
      sort: {fields: frontmatter___date, order: ASC}
      limit: 6
    ) {
      nodes {
        frontmatter {
          titre
          marque
          categorie
          card_image {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
          card_image_alt
          hero_image {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
          hero_image_alt
          hero_image_credit_link
          hero_image_credit_text
          accueil
          actif
          date(formatString: "MMMM D, YYYY")
        }
        id
        slug
      }
      totalCount
    }
  }
`